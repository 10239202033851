import axios from 'axios';

import {baseUrl} from '@/js/util.js'

// 新增机具统计数据
export const UpdateMachineryStatistics=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/ProjectDescription/UpdateMachineryStatistics`,
        data:datas
    })
}

// 删除机具统计数据
export const DeleteMachineryStatisticsList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/ProjectDescription/DeleteMachineryStatisticsList`,
        params:datas
    })
}


// 机具统计数据列表
export const GetMachineryStatisticsList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/ProjectDescription/GetMachineryStatisticsList`,
        params:datas
    })
}
