<template>
  <div class="addImplement">
    <div class="addImplement-wrap">
      <div class="addImplement-top">
        <div>{{ addtitle }}</div>
        <img @click="closefn" src="@/assets/images/pop01.png" alt="" />
      </div>
      <div class="addImplement-content">
        <div class="addImplement-input">
          <span>机具名称</span>
          <div>
            <el-input
              v-model.number="inputs1"
              placeholder="请输入名称"
            ></el-input>
          </div>
        </div>
        <div class="addImplement-input addImplement-input1">
          <span>机具数量</span>
          <div>
            <el-input v-model.number="inputs2" placeholder="请输入" type="tel" min="0" max="102400" @input="val = String(val).replace(/[^\d]/g,'')"></el-input>
          </div>
        </div>
      </div>

      <div class="add-wrap noscrollstyle">
        <div
          class="addImplement-content"
          v-if="showstyle"
          v-for="(item, index) in tableDatas"
          :key="index"
        >
          <div class="addImplement-input">
            <span>机具名称</span>
            <div>
              <el-input
                v-model.number="item.inputs1"
                placeholder="请输入名称"
              ></el-input>
            </div>
          </div>
          <div class="addImplement-input addImplement-input1">
            <span>机具数量</span>
            <div>
              <el-input v-model="item.inputs2" placeholder="请输入"></el-input>
            </div>
          </div>

          <img
            class="dels"
            @click="delfn(item, index)"
            src="@/assets/images/common/del.png"
            alt=""
          />
        </div>
      </div>

      <div class="addImplement-add" v-if="addtitle == '添加机具'">
        <div class="addImplement-btn" @click="addline">
          <img src="@/assets/images/common/add.png" alt="" />
          <span>再添加一种机具</span>
        </div>
      </div>
      <div class="addImplement-bottom">
        <!-- <button @click="closefn">取消</button>  -->
        <button
          @click="AddExtractApplyfn"
          :class="{ def: !inputs1 || !inputs2 }"
        >
          确定
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { getStorage } from "@/js/common";
import { UpdateMachineryStatistics } from "@/js/ImplementStatistical";
export default {
  props: {
    addfalse: Boolean,
    bonus: Number,
    addtitle: String,
    editdata: Object,
  },
  setup(props, { emit }) {
    const state = reactive({
      inputs1: "", //
      inputs2: "",
      value: "",
      Primarydata: [],
      Primaryobj: null,
      adding: false,
      num: 0,
      showstyle: false,
      addtitle: "",
      editid:'',
      tableDatas: [
        {
          inputs1: "", //
          inputs2: "",
        },
      ],
    });
    const closefn = () => {
      emit("addfalsefn");
    };
    const cdata = getStorage("bscdata");
    const methods = {
      // 添加一行
      addline: () => {
        ++state.num;
        state.showstyle = true;
        let newValue = {};
        if (state.num > 1) {
          newValue = {
            inputs1: "", //
            inputs2: "",
          };

          state.tableDatas.push(newValue);
        }
      },

      // 删除一行
      delfn: (item, index) => {
        state.tableDatas.splice(index, 1);
      },
      // 添加机具
      UpdateMachineryStatistics: () => {
        let data01 = [];
        let data02 = [];
        let datas = [];
        if (state.addtitle == "编辑机具") {
          datas = [
            {
              id:state.editid,
              machineryName: state.inputs1,
              machineryNumber: state.inputs2,
              UserID: cdata.uid,
              projectId: cdata.pid,
            }]
        } else {
          data01 = [
            {
              machineryName: state.inputs1,
              machineryNumber: state.inputs2,
              UserID: cdata.uid,
              projectId: cdata.pid,
            },
          ];
          data02 = [];
          if (state.tableDatas.length > 0) {
            state.tableDatas.forEach((item) => {
              console.log(item);
              if (item.inputs1 && item.inputs2) {
                data02.push({
                  machineryName: item.inputs1,
                  machineryNumber: item.inputs2,
                  UserID: cdata.uid,
                  projectId: cdata.pid,
                });
              }
            });
          }

          datas = data01.concat(data02);
        }

        console.log(datas, "datas");
        UpdateMachineryStatistics(datas).then((res) => {
          
          console.log(res);
          if (res.data.Code == 1) {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "success",
            });
            emit("addfalsefn");
            emit("GetMachineryStatisticsList");
          } else {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "error",
            });
          }
        });
      },
      AddExtractApplyfn: () => {
        if (!state.inputs1 || !state.inputs2) {
          return;
        }
        methods.UpdateMachineryStatistics();
      },
    };
    onMounted(() => {
      state.addtitle = props.addtitle;
      if (state.addtitle == "编辑机具") {
        state.inputs1 = props.editdata.MachineryName;
        state.inputs2 = props.editdata.MachineryNumber;
        state.editid=props.editdata.ID;
      }
      console.log(props, state.addtitle);
    });
    return {
      ...methods,
      closefn,
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss" scoped>
.addImplement {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  .addImplement-wrap {
    width: 576px;
    min-height: 235px;
    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    .addImplement-top {
      padding: 0 20px;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #e3edf8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #666666;
      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
  }

  .add-wrap {
    max-height: 500px;
    overflow-y: auto;
  }

  .addImplement-content {
    width: 100%;
    padding: 0 20px 20px;
    // border-bottom: 1px solid #e3edf8;
    box-sizing: border-box;

    display: flex;
    justify-content: left;
    align-items: center;
    .annotation {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #00a5ff;
      text-align: center;
      margin-top: 5px;
      margin-left: 20px;
      box-sizing: border-box;
    }
    .addImplement-input {
      display: flex;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #555555;
      margin-top: 20px;
      box-sizing: border-box;
      align-items: center;
      span {
        display: inline-block;
        width: 70px;
        padding-right: 11px;
        box-sizing: border-box;
      }
      div {
        width: 187px;
      }
    }

    .dels {
      width: 30px;
      height: 30px;
      display: flex;
      margin-top: auto;
    }

    .addImplement-input1 {
      margin: 20px 20px 0;
      box-sizing: border-box;
      div {
        width: 100px;
      }
    }
  }

  .addImplement-add {
    margin-left: 109px;
    margin-bottom: 20px;
    .addImplement-btn {
      width: 128px;
      height: 32px;
      background: rgba(0, 180, 255, 0.1);
      border-radius: 4px;
      border: 1px solid #0083ff;

      font-size: 12px;
      color: #0083ff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 12px;
        height: 12px;
        margin-right: 3px;
        box-sizing: border-box;
      }
    }
  }

  .addImplement-bottom {
    text-align: right;
    padding: 10px 20px;
    box-sizing: border-box;
    border-top: 1px solid #c6c6c6;
    button {
      display: inline-block;
      width: 68px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid #c6c6c6;
      line-height: 36px;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #adbaae;
      margin-left: 10px;
      box-sizing: border-box;
      cursor: pointer;

      // &:nth-child(2) {
        background: #027aff;
        color: #fff;
      // }
      &.def {
        background: #fff;
        color: #adbaae;
        cursor: default;
      }
    }
  }
}
</style>